// src/pages/ContabilidadeArrecadacao.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function ContabilidadeArrecadacao() {
  return (
    <Container sx={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Contabilidade - Arrecadação
      </Typography>
      <Typography variant="body1">
        Conteúdo da página de Contabilidade (Arrecadação).
      </Typography>
    </Container>
  );
}

export default ContabilidadeArrecadacao;
