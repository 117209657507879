// src/pages/Sobre.js
import React from 'react';
import { Container, Typography, Box, Divider } from '@mui/material';

function Sobre() {
  return (
    <Container sx={{ pt:4, pb:4 }}>
      <Typography variant="h4" gutterBottom sx={{ fontWeight:'bold' }}>
        Sobre o Espelho Público
      </Typography>

      <Divider sx={{ mb:3 }} />

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        O <strong>Espelho Público</strong> é um portal de transparência que facilita o acesso a informações sobre compras, despesas e arrecadações de diferentes prefeituras. Nosso objetivo é promover a transparência pública, tornando mais fácil e intuitivo entender como os recursos são aplicados.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Através da seleção da prefeitura desejada, você visualiza um conjunto de dados detalhados referentes ao ano selecionado. Os dados são apresentados em gráficos interativos, permitindo identificar os maiores fornecedores, entender a distribuição de gastos por categoria e acompanhar a evolução dos pagamentos ao longo do tempo.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mt:4, fontWeight:'bold' }}>
        Origem dos Dados
      </Typography>

      <Divider sx={{ mb:3 }} />

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Os dados exibidos no Espelho Público são obtidos através de <strong>APIs públicas</strong> disponibilizadas por sistemas terceiros, especialmente portais oficiais de transparência e dados abertos. Estes sistemas seguem diretrizes de transparência governamental, disponibilizando informações orçamentárias de forma acessível.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Ao selecionar uma prefeitura, realizamos chamadas a essas APIs, filtrando os dados pelo período (ano) desejado. As informações retornadas incluem dados sobre empenhos, pagamentos e fornecedores, entre outros detalhes financeiros.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Após obtermos os dados brutos, eles são processados no próprio navegador, agregados e exibidos em gráficos de barras, linhas, pizza e outras visualizações. Dessa forma, apresentamos um panorama claro e objetivo dos gastos públicos.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mt:4, fontWeight:'bold' }}>
        Como Utilizar
      </Typography>

      <Divider sx={{ mb:3 }} />

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Para começar, vá até a página inicial e selecione a prefeitura desejada no carrossel exibido no topo. O portal então exibirá o valor total gasto no ano atual. Caso queira aprofundar, acesse as páginas de <strong>Despesas</strong>, <strong>Compras</strong> ou <strong>Arrecadação</strong> para visualizar gráficos detalhados, filtrar dados por fornecedor, e explorar a evolução dos pagamentos ao longo de vários anos.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Os gráficos são interativos, permitindo destacar ou isolar informações específicas, removendo fornecedores individualmente ou ajustando parâmetros conforme necessário.
      </Typography>

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        Caso queira obter os dados brutos utilizados, eles estão acessíveis através das APIs das prefeituras correspondentes (normalmente informadas nos portais oficiais de transparência do município). Basta visitar o site oficial da prefeitura selecionada, localizar a seção de dados abertos ou transparência, e acessar as rotas de API disponibilizadas.
      </Typography>

      <Typography variant="h5" gutterBottom sx={{ mt:4, fontWeight:'bold' }}>
        Compromisso com a Transparência
      </Typography>

      <Divider sx={{ mb:3 }} />

      <Typography variant="body1" paragraph sx={{ lineHeight:1.6 }}>
        O Espelho Público não é um órgão oficial, mas sim uma ferramenta criada para promover a cidadania ativa, permitindo que qualquer pessoa entenda melhor a gestão dos recursos públicos. Acreditamos que, ao tornar a informação mais acessível e clara, fortalecemos a democracia e incentivamos uma participação mais consciente da sociedade na fiscalização dos gastos governamentais.
      </Typography>

    </Container>
  );
}

export default Sobre;
