// src/pages/HomePage.js

import React, { useEffect, useState } from 'react';
import { 
  Container, 
  Typography, 
  Box, 
  CircularProgress, 
  Button
} from '@mui/material';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';

const prefeiturasDisponiveis = [
  'São João do Sul', 
  'Araranguá',
  'Prefeitura X',
  'Prefeitura Y',
  'Prefeitura Z'
];

const prefeituras = {
  'São João do Sul': 'https://saojoaodosul.atende.net/api/WCPDadosAbertos/despesas',
  'Araranguá': 'https://ararangua.atende.net/api/WCPDadosAbertos/despesas',
  'Prefeitura X': 'https://exemplo.com/api/despesas',
  'Prefeitura Y': 'https://exemplo.com/api/despesas',
  'Prefeitura Z': 'https://exemplo.com/api/despesas'
};

function formatter(valor) {
  return Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
}

// Setas personalizadas (um pouco maiores)
function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div 
      className={className}
      onClick={onClick}
      style={{
        width:'40px',
        height:'40px',
        backgroundColor:'#fff',
        borderRadius:'50%',
        border:'1px solid #ddd',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer',
        position:'absolute',
        top:'50%',
        transform:'translateY(-50%)',
        left:'-45px',
        zIndex:2,
        boxShadow:'0 0 5px rgba(0,0,0,0.2)'
      }}
    >
      <KeyboardArrowLeft style={{color:'#666'}}/>
    </div>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div 
      className={className}
      onClick={onClick}
      style={{
        width:'40px',
        height:'40px',
        backgroundColor:'#fff',
        borderRadius:'50%',
        border:'1px solid #ddd',
        display:'flex',
        alignItems:'center',
        justifyContent:'center',
        cursor:'pointer',
        position:'absolute',
        top:'50%',
        transform:'translateY(-50%)',
        right:'-45px',
        zIndex:2,
        boxShadow:'0 0 5px rgba(0,0,0,0.2)'
      }}
    >
      <KeyboardArrowRight style={{color:'#666'}}/>
    </div>
  );
}

function HomePage() {
  const [prefeitura, setPrefeitura] = useState('São João do Sul');
  const ano = new Date().getFullYear();
  const [loading, setLoading] = useState(true);
  const [totalGasto, setTotalGasto] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const urlBase = prefeituras[prefeitura];
      const dataInicial = `01/01/${ano}`;
      const dataFinal = `31/12/${ano}`;
      const url = `${urlBase}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error('Erro na requisição:', response.status, response.statusText);
          setLoading(false);
          return;
        }

        const dados = await response.json();
        if (dados.retorno && dados.retorno.length > 0) {
          let total = 0;
          dados.retorno.forEach(item => {
            const valor = parseFloat(item.valorPago) || 0;
            total += valor;
          });
          setTotalGasto(total);
        } else {
          setTotalGasto(0);
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
        setTotalGasto(0);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [prefeitura, ano]);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: Math.min(prefeiturasDisponiveis.length, 5),
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    pauseOnFocus: false,
    pauseOnDotsHover: false,
    prevArrow: <SamplePrevArrow />,
    nextArrow: <SampleNextArrow />
  };

  return (
    <Box sx={{ backgroundColor:'#fff', minHeight:'80vh', py:4, fontFamily:'"Roboto", sans-serif' }}>
      <Container maxWidth="lg">
        <Box mb={4} sx={{position:'relative'}}>
          <Box sx={{ border:'1px solid #ddd', borderRadius:'4px', p:1, overflow:'hidden' }}>
            <Slider {...settings}>
              {prefeiturasDisponiveis.map((pf) => (
                <Box 
                  key={pf}
                  onClick={() => setPrefeitura(pf)}
                  sx={{
                    height:'20px',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    cursor:'pointer',
                    backgroundColor: (pf === prefeitura) ? '#ffe5e5' : '#fff',
                    borderRight:'1px solid #eee',
                    transition:'transform 0.2s ease-in-out',
                    '&:hover':{
                      backgroundColor:'#f9f9f9',
                      transform:'scale(1.02)'
                    }
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      color:(pf===prefeitura?'red':'#333'), 
                      fontWeight:(pf===prefeitura?'bold':'normal'),
                      textAlign:'center',
                      fontFamily:'"Roboto", sans-serif',
                      lineHeight:'normal'
                    }}
                  >
                    {pf}
                  </Typography>
                </Box>
              ))}
            </Slider>
          </Box>
        </Box>

        {loading ? (
          <Box textAlign="center" mt={4}>
            <CircularProgress sx={{color:'red'}}/>
            <Typography variant="body1" sx={{color:'#333', mt:1, fontFamily:'"Roboto", sans-serif'}}>Carregando dados...</Typography>
          </Box>
        ) : (
          <Box textAlign="center" display="flex" flexDirection="column" gap={4} alignItems="center" mt={4}>
            <Typography 
              variant="h3" 
              sx={{ fontWeight:'bold', color:'#111', fontFamily:'"Roboto", sans-serif' }}
            >
              Portal de Transparência
            </Typography>

            <Typography variant="h5" sx={{maxWidth:'600px', color:'#555', lineHeight:1.5, fontFamily:'"Roboto", sans-serif'}}>
              Veja quanto a prefeitura de <span style={{fontWeight:'bold', color:'red'}}>{prefeitura}</span> gastou no ano de {ano}.
              Exibimos os dados em gráficos para facilitar sua compreensão.
            </Typography>

            <Typography 
              variant="h2" 
              sx={{ 
                fontWeight:'bold', 
                color:'red',
                fontFamily:'"Roboto", sans-serif'
              }}
            >
              {formatter(totalGasto)}
            </Typography>

            <Typography variant="body1" sx={{ color:'#555', maxWidth:'600px', lineHeight:1.5, fontFamily:'"Roboto", sans-serif'}}>
              Navegue pelas páginas para detalhar despesas, compras e arrecadações.
            </Typography>

            <Box display="flex" gap={2} flexWrap="wrap" justifyContent="center" mt={4}>
              <Button 
                variant="contained" 
                component={Link} 
                to="/contabilidade-despesas" 
                sx={{ 
                  backgroundColor:'red', 
                  color:'#fff', 
                  fontWeight:'bold', 
                  fontFamily:'"Roboto", sans-serif',
                  '&:hover':{backgroundColor:'darkred'},
                  textTransform:'none',
                  textAlign:'center'
                }}
              >
                Contabilidade - Despesas
              </Button>
              <Button 
                variant="contained" 
                component={Link} 
                to="/compras" 
                sx={{ 
                  backgroundColor:'red', 
                  color:'#fff', 
                  fontWeight:'bold', 
                  fontFamily:'"Roboto", sans-serif',
                  '&:hover':{backgroundColor:'darkred'},
                  textTransform:'none',
                  textAlign:'center'
                }}
              >
                Compras
              </Button>
              <Button 
                variant="contained" 
                component={Link} 
                to="/contabilidade-arrecadacao" 
                sx={{ 
                  backgroundColor:'red', 
                  color:'#fff', 
                  fontWeight:'bold', 
                  fontFamily:'"Roboto", sans-serif',
                  '&:hover':{backgroundColor:'darkred'},
                  textTransform:'none',
                  textAlign:'center'
                }}
              >
                Contabilidade - Arrecadação
              </Button>
            </Box>
          </Box>
        )}
      </Container>
    </Box>
  );
}

export default HomePage;
