// src/components/Footer.js
import React from 'react';
import { Box, Typography } from '@mui/material';

function Footer() {
  return (
    <Box sx={{textAlign:'center', py:2, borderTop:'1px solid #ddd', mt:4}}>
      <Typography variant="body2" sx={{color:'#555'}}>
        © {new Date().getFullYear()} Espelho Público - Transparência e Controle
      </Typography>
    </Box>
  );
}

export default Footer;
