// src/pages/Compras.js
import React from 'react';
import { Container, Typography } from '@mui/material';

function Compras() {
  return (
    <Container sx={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Portal de Compras
      </Typography>
      <Typography variant="body1">
        Conteúdo da página de Compras.
      </Typography>
    </Container>
  );
}

export default Compras;
