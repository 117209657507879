// src/pages/ContabilidadeDespesas.js

import React, { useEffect, useState, useMemo } from 'react';
import {
  Container,
  Typography,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  TextField,
  CircularProgress,
  Box,
  Paper,
  Button
} from '@mui/material';
import Plot from 'react-plotly.js';
import { DataGrid } from '@mui/x-data-grid';

const prefeituras = {
  'São João do Sul': 'https://saojoaodosul.atende.net/api/WCPDadosAbertos/despesas',
  'Araranguá': 'https://ararangua.atende.net/api/WCPDadosAbertos/despesas'
};

function normalizeCNPJ(cnpj) {
  return cnpj.replace(/\D/g, '');
}

// Formatter para valores monetários em Real
const formatter = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

function ContabilidadeDespesas() {
  const [prefeitura, setPrefeitura] = useState('São João do Sul');
  const [ano, setAno] = useState(new Date().getFullYear());
  const [loading, setLoading] = useState(false);

  const [fornecedoresBruto, setFornecedoresBruto] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [valoresCategorias, setValoresCategorias] = useState([]);

  const [filtroFornecedor, setFiltroFornecedor] = useState('');
  const [valorMin, setValorMin] = useState('');
  const [valorMax, setValorMax] = useState('');

  const [cnpjFornecedor, setCnpjFornecedor] = useState('');
  const [anoInicialFornecedor, setAnoInicialFornecedor] = useState(2020);
  const [dadosFornecedor, setDadosFornecedor] = useState([]);
  const [loadingFornecedor, setLoadingFornecedor] = useState(false);

  const [page, setPage] = useState(0);
  const pageSize = 15; 

  const anosDisponiveis = [2024, 2023, 2022, 2021, 2020];

  function handleClickPizza(event) {
    const categoriaClicada = event.points[0].label;
    alert(`Categoria selecionada: ${categoriaClicada}. Poderíamos filtrar fornecedores dessa categoria aqui.`);
  }

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const urlBase = prefeituras[prefeitura];
      const dataInicial = `01/01/${ano}`;
      const dataFinal = `31/12/${ano}`;
      const url = `${urlBase}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          console.error('Erro na requisição:', response.status, response.statusText);
          setLoading(false);
          return;
        }

        const dados = await response.json();
        console.log('Dados recebidos da API:', dados);

        if (dados.retorno && dados.retorno.length > 0) {
          const agrupados = {};
          const categoriasMapa = {};

          dados.retorno.forEach((item) => {
            const cnpj = item.cpfCnpjCredor;
            const valor = parseFloat(item.valorPago) || 0;

            if (agrupados[cnpj]) {
              agrupados[cnpj].valor += valor;
            } else {
              agrupados[cnpj] = {
                nomeCredor: item.nomeCredor,
                cpfCnpjCredor: cnpj,
                valor: valor,
              };
            }

            const categoria = item.orgaoDescricao || 'Desconhecido';
            if (categoriasMapa[categoria]) {
              categoriasMapa[categoria] += valor;
            } else {
              categoriasMapa[categoria] = valor;
            }
          });

          let arrayAgrupados = Object.values(agrupados);
          arrayAgrupados.sort((a, b) => b.valor - a.valor);

          setFornecedoresBruto(arrayAgrupados);

          const chavesCategorias = Object.keys(categoriasMapa);
          const valoresCat = Object.values(categoriasMapa);
          setCategorias(chavesCategorias);
          setValoresCategorias(valoresCat);
        } else {
          console.warn('Nenhum dado encontrado para esse ano e prefeitura.');
          setFornecedoresBruto([]);
          setCategorias([]);
          setValoresCategorias([]);
        }
      } catch (error) {
        console.error('Erro ao buscar dados:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [prefeitura, ano]);

  const fornecedoresFiltrados = useMemo(() => {
    let filtrados = fornecedoresBruto;

    if (filtroFornecedor.trim() !== '') {
      filtrados = filtrados.filter((f) =>
        f.nomeCredor.toLowerCase().includes(filtroFornecedor.toLowerCase())
      );
    }

    const minVal = valorMin !== '' ? parseFloat(valorMin) : null;
    const maxVal = valorMax !== '' ? parseFloat(valorMax) : null;
    if (minVal !== null) {
      filtrados = filtrados.filter(f => f.valor >= minVal);
    }
    if (maxVal !== null) {
      filtrados = filtrados.filter(f => f.valor <= maxVal);
    }

    return filtrados;
  }, [fornecedoresBruto, filtroFornecedor, valorMin, valorMax]);

  const startIndex = page * pageSize;
  const endIndex = startIndex + pageSize;
  const fornecedoresPagina = fornecedoresFiltrados.slice(startIndex, endIndex);

  // Cálculo do total gasto
  const totalGasto = fornecedoresFiltrados.reduce((sum, f) => sum + f.valor, 0);

  // Formatando valores na tabela
  const rows = fornecedoresPagina.map((f) => {
    const globalIndex = fornecedoresFiltrados.indexOf(f);
    return {
      id: globalIndex,
      nomeCredor: f.nomeCredor,
      valor: formatter.format(f.valor), // Valor formatado
      cpfCnpjCredor: f.cpfCnpjCredor
    };
  });

  const columns = [
    { field: 'nomeCredor', headerName: 'Fornecedor', width: 200 },
    { field: 'valor', headerName: 'Valor Pago (R$)', width: 150 }
  ];

  // Primeiro gráfico (fornecedores)
  // Nome do fornecedor dentro da barra
  // Hover com nome e valor
  const fornecedoresData = fornecedoresPagina.map((item) => ({
    type: 'bar',
    orientation: 'h',
    y: [item.nomeCredor],
    x: [item.valor],
    text: [item.nomeCredor],
    textposition: 'inside',
    insidetextanchor: 'middle',
    textfont: { color: '#fff', size: 10 },
    hovertemplate: 'Fornecedor: %{y}<br>Valor Pago: R$ %{x:,.2f}<extra></extra>'
  }));
  const chartHeight = fornecedoresPagina.length * 40 + 200; 

  const buscarDadosFornecedor = () => {
    if (cnpjFornecedor && anoInicialFornecedor) {
      setLoadingFornecedor(true);
      const anoAtual = new Date().getFullYear();
      const anos = [];
      for (let a = anoInicialFornecedor; a <= anoAtual; a++) {
        anos.push(a);
      }

      const normalizedUserCNPJ = normalizeCNPJ(cnpjFornecedor);

      const urlBase = prefeituras[prefeitura];

      const promises = anos.map((anoIter) => {
        const dataInicial = `01/01/${anoIter}`;
        const dataFinal = `31/12/${anoIter}`;
        const url = `${urlBase}?dataInicial=${dataInicial}&dataFinal=${dataFinal}`;

        return fetch(url)
          .then((res) => res.json())
          .then((dados) => {
            if (dados.retorno && dados.retorno.length > 0) {
              const dadosFornecedorAno = dados.retorno.filter(
                (item) => normalizeCNPJ(item.cpfCnpjCredor) === normalizedUserCNPJ
              );

              let totalAno = 0;
              dadosFornecedorAno.forEach((item) => {
                const valor = parseFloat(item.valorPago) || 0;
                totalAno += valor;
              });

              return {
                ano: anoIter,
                total: totalAno,
              };
            } else {
              return { ano: anoIter, total: 0 };
            }
          })
          .catch((error) => {
            console.error(`Erro ao carregar dados do ano ${anoIter}:`, error);
            return { ano: anoIter, total: 0 };
          });
      });

      Promise.all(promises).then((results) => {
        results.sort((a, b) => a.ano - b.ano);
        setDadosFornecedor(results);
        setLoadingFornecedor(false);
      });
    }
  };

  // Calcular a soma de todos os anos do fornecedor selecionado
  const totalTodosAnos = dadosFornecedor.reduce((acc, cur) => acc + cur.total, 0);

  return (
    <Container sx={{ paddingTop: '20px' }}>
      <Typography variant="h4" gutterBottom>
        Contabilidade - Despesas
      </Typography>

      <Typography variant="h5" align="center" paragraph>
        Total Gasto no Ano Selecionado: {formatter.format(totalGasto)}
      </Typography>

      <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
        <FormControl sx={{ minWidth: 200 }}>
          <InputLabel id="prefeitura-label">Prefeitura</InputLabel>
          <Select
            labelId="prefeitura-label"
            id="prefeitura-select"
            value={prefeitura}
            label="Prefeitura"
            onChange={(e) => { setPrefeitura(e.target.value); setPage(0); }}
          >
            {Object.keys(prefeituras).map((nome, index) => (
              <MenuItem key={index} value={nome}>{nome}</MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ minWidth: 120 }}>
          <InputLabel id="ano-label">Ano</InputLabel>
          <Select
            labelId="ano-label"
            id="ano-select"
            value={ano}
            label="Ano"
            onChange={(e) => { setAno(e.target.value); setPage(0); }}
          >
            {anosDisponiveis.map((anoOption, index) => (
              <MenuItem key={index} value={anoOption}>
                {anoOption}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <TextField
          label="Filtrar Fornecedor (nome)"
          variant="outlined"
          value={filtroFornecedor}
          onChange={(e) => { setFiltroFornecedor(e.target.value); setPage(0); }}
          sx={{ minWidth: 200 }}
        />

        <TextField
          label="Valor Mínimo (R$)"
          variant="outlined"
          type="number"
          value={valorMin}
          onChange={(e) => { setValorMin(e.target.value); setPage(0); }}
          sx={{ minWidth: 120 }}
        />

        <TextField
          label="Valor Máximo (R$)"
          variant="outlined"
          type="number"
          value={valorMax}
          onChange={(e) => { setValorMax(e.target.value); setPage(0); }}
          sx={{ minWidth: 120 }}
        />
      </Box>

      {loading ? (
        <Box textAlign="center" mt={2}>
          <CircularProgress />
          <Typography variant="body1">Carregando dados...</Typography>
        </Box>
      ) : (
        <Box display="flex" flexDirection="column" gap={4}>

          <Box display="flex" flexDirection="row" gap="20px">
            <Paper sx={{ width: '30%', padding: '10px', border: '1px solid #ccc', borderRadius: '5px', display:'flex', flexDirection:'column' }}>
              <Typography variant="body2" paragraph>
                Navegue pelas páginas usando os botões abaixo.
              </Typography>
              <Box sx={{ height: '800px', overflow:'auto', mb:2 }}>
                <DataGrid
                  rows={rows}
                  columns={columns}
                  disableColumnMenu
                  disableColumnFilter
                  disableColumnSelector
                  hideFooter
                  autoHeight={false}
                />
              </Box>
              <Box sx={{ display: 'flex', gap: '10px', mt: 2, justifyContent:'center' }}>
                <Button variant="contained" onClick={() => setPage(Math.max(page - 1, 0))} disabled={page === 0}>
                  Anterior
                </Button>
                <Button variant="contained" onClick={() => {
                  const totalFiltrados = fornecedoresFiltrados.length;
                  const maxPage = Math.floor((totalFiltrados - 1) / pageSize);
                  if (page < maxPage) setPage(page + 1);
                }}>
                  Próximo
                </Button>
              </Box>
            </Paper>

            <Paper sx={{ flexGrow:1, display:'flex', justifyContent:'center', alignItems:'center', border: '1px solid #ccc', borderRadius: '5px', padding:'10px' }}>
              {fornecedoresPagina.length > 0 ? (
                fornecedoresData.length > 0 ? (
                  <Plot
                    data={fornecedoresData}
                    layout={{
                      title: `Fornecedores (Página ${page+1}) - Ano ${ano}`,
                      showlegend: false,
                      margin: { l:150, r:50, t:50, b:100 },
                      xaxis: {
                        title: 'Valor Pago (R$)',
                        tickprefix: 'R$ ',
                        tickformat: ',.2f',
                        automargin: true,
                      },
                      yaxis: {
                        showticklabels: false,
                        automargin: true,
                      },
                      bargap: 0.1,
                    }}
                    config={{ responsive: true, scrollZoom: true }}
                    useResizeHandler
                    style={{ width:'90%', height: chartHeight+'px' }}
                  />
                ) : (
                  <Typography variant="body1">
                    Não há fornecedores visíveis nesta página.
                  </Typography>
                )
              ) : (
                <Typography variant="body1">Nenhum fornecedor para esta página.</Typography>
              )}
            </Paper>
          </Box>

          {categorias.length > 0 && valoresCategorias.length > 0 && (
            <Paper sx={{ width: '100%', border: '1px solid #ccc', borderRadius: '5px', padding:'10px', display:'flex', justifyContent:'center', alignItems:'center', height:'90vh', maxWidth:'100%' }}>
              <Plot
                data={[
                  {
                    type: 'pie',
                    labels: categorias,
                    values: valoresCategorias,
                    textinfo: 'label+percent',
                    textposition: 'inside',
                    insidetextorientation: 'radial',
                    hole: 0.2,
                    automargin: true,
                    hovertemplate: '%{label}<br>Valor: R$ %{value:,.2f}<extra></extra>'
                  },
                ]}
                layout={{
                  title: 'Distribuição de Gastos por Categoria',
                  showlegend: true,
                  legend: {
                    orientation: 'h',
                    x:0.5,
                    y:-0.2,
                    xanchor:'center',
                    yanchor:'top',
                    font:{size:9}
                  },
                  margin: { l:50, r:50, t:50, b:150 },
                }}
                useResizeHandler
                config={{responsive:true}}
                style={{ width:'90%', height:'90%' }}
                onClick={handleClickPizza}
              />
            </Paper>
          )}

          {/* Evolução dos Pagamentos ao Fornecedor (5º gráfico) */}
          <Paper sx={{ width: '100%', border: '1px solid #ccc', borderRadius: '5px', padding:'10px', display:'flex', flexDirection:'column', gap:'20px', justifyContent:'center', alignItems:'center', height:'80vh', maxWidth:'100%' }}>
            <Box width='90%' height='90%' display="flex" flexDirection="column" gap="20px">
              <Typography variant="h5" gutterBottom>
                Evolução dos Pagamentos ao Fornecedor
              </Typography>

              {/* Mensagem com soma total dos anos */}
              {cnpjFornecedor && dadosFornecedor.length > 0 && (
                <Typography variant="body1">
                  O valor pago para o fornecedor {cnpjFornecedor} no período selecionado é {formatter.format(totalTodosAnos)}
                </Typography>
              )}

              <Box sx={{ display: 'flex', gap: '10px', flexWrap: 'wrap', marginBottom: '20px' }}>
                <TextField
                  label="CNPJ Fornecedor"
                  variant="outlined"
                  value={cnpjFornecedor}
                  onChange={(e) => setCnpjFornecedor(e.target.value)}
                  sx={{ minWidth: 200 }}
                />

                <FormControl sx={{ minWidth: 120 }}>
                  <InputLabel id="ano-inicial-label">Ano Inicial</InputLabel>
                  <Select
                    labelId="ano-inicial-label"
                    id="ano-inicial-select"
                    value={anoInicialFornecedor}
                    label="Ano Inicial"
                    onChange={(e) => setAnoInicialFornecedor(e.target.value)}
                  >
                    {anosDisponiveis.slice().reverse().map((anoOption, index) => (
                      <MenuItem key={index} value={anoOption}>
                        {anoOption}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  onClick={buscarDadosFornecedor}
                  sx={{ height: '56px' }}
                >
                  Buscar
                </Button>
              </Box>

              {loadingFornecedor ? (
                <Box textAlign="center" mt={2}>
                  <CircularProgress />
                  <Typography variant="body1">Carregando dados do fornecedor...</Typography>
                </Box>
              ) : dadosFornecedor.length > 0 ? (
                <Plot
                  data={[
                    {
                      x: dadosFornecedor.map((item) => item.ano),
                      y: dadosFornecedor.map((item) => item.total),
                      type: 'scatter',
                      mode: 'lines+markers',
                      marker: { color: 'blue' },
                      name: 'Fornecedor',
                      hovertemplate: 'R$ %{y:,.2f}<extra></extra>'
                    },
                  ]}
                  layout={{
                    title: `Evolução dos Pagamentos ao Fornecedor ${cnpjFornecedor}`,
                    xaxis: { title: 'Ano' },
                    yaxis: { title: 'Valor Pago (R$)', tickprefix:'R$ ', tickformat:',.2f' },
                    showlegend: false,
                    margin: { l: 120, r: 50, t:80, b: 50 }, // Maior margem superior para separar texto
                  }}
                  useResizeHandler
                  style={{ width:'100%', height:'80%' }}
                />
              ) : cnpjFornecedor ? (
                <Typography variant="body1">Nenhum dado encontrado para o fornecedor {cnpjFornecedor}.</Typography>
              ) : null}
            </Box>
          </Paper>
        </Box>
      )}
    </Container>
  );
}

export default ContabilidadeDespesas;
