// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './pages/HomePage';
import ContabilidadeDespesas from './pages/ContabilidadeDespesas';
import Compras from './pages/Compras';
import ContabilidadeArrecadacao from './pages/ContabilidadeArrecadacao';
import Sobre from './pages/Sobre';
import Header from './components/Header';
import Footer from './components/Footer';
import { Box } from '@mui/material';

function App() {
  return (
    <Router>
      <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <Header />
        <Box component="main" sx={{ flexGrow: 1 }}>
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/contabilidade-despesas" element={<ContabilidadeDespesas />} />
            <Route path="/compras" element={<Compras />} />
            <Route path="/contabilidade-arrecadacao" element={<ContabilidadeArrecadacao />} />
            <Route path="/sobre" element={<Sobre />} />
          </Routes>
        </Box>
        <Footer />
      </Box>
    </Router>
  );
}

export default App;
