// src/components/Header.js
import React from 'react';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

function Header() {
  return (
    <AppBar 
      position="static" 
      sx={{ 
        margin: 0, 
        padding: 0,
        backgroundColor: 'black' // Cor do fundo preta
      }}
    >
      <Toolbar>
        <AccountBalanceIcon sx={{ mr: 2, color: 'white' }} />
        <Typography
          variant="h6"
          component={Link}
          to="/"
          sx={{ 
            flexGrow: 1, 
            color: 'inherit', 
            textDecoration: 'none',
            color: 'white' // Texto branco para contraste
          }}
        >
          Espelho Público
        </Typography>
        <Button color="inherit" component={Link} to="/compras">
          Compras
        </Button>
        <Button color="inherit" component={Link} to="/contabilidade-despesas">
          Despesas
        </Button>
        <Button color="inherit" component={Link} to="/contabilidade-arrecadacao">
          Arrecadação
        </Button>
        <Button color="inherit" component={Link} to="/sobre">
          Sobre
        </Button>
      </Toolbar>
    </AppBar>
  );
}

export default Header;
